import React from "react";
import "./MoveCategory.css";
const MoveCategory = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="move-category-main-container">
      <div className="move-category-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Move Category</p>
      </div>
      <div className="move-category-title-div">
        <p>How to move a Category from one category group to another</p>
      </div>
      <div className="move-category-section-container1">
        <div className="move-category-section-container1-description">
          <p>
            1. On your Best Budget App, open Budget page and tap on “Manage
            Categories” icon located on the top right corner.
          </p>
        </div>
        <div className="move-category-section-container1-img1-div">
          <div className="move-category-section-container1-img1"></div>
        </div>
      </div>
      <div className="move-category-section-container2">
        <div className="move-category-section-container2-description">
          <p>
            2. Locate the desired category you wish to move to another category
            group.
          </p>
          <p> 3. Click on the menu icon placed at the left side of the
            category.</p>
            <p> 4. Select 'Switch Group' from the menu options.</p>
        </div>
        <div className="move-category-section-container2-img1-div">
          <div className="move-category-section-container2-img1"></div>
        </div>
      </div>
      <div className="move-category-section-container3">
        <div className="move-category-section-container3-description">
          <p>
          5. Tap on the desired group you wish to switch the category to.
          </p>
        </div>
        <div className="move-category-section-container3-img1-div">
          <div className="move-category-section-container3-img1"></div>
        </div>
      </div>
      <div className="move-category-section-container4">
        <div className="move-category-section-container4-description">
          <p>
          6. Click on 'Done' to save the changes.
          </p>
        </div>
        <div className="move-category-section-container4-img1-div">
          <div className="move-category-section-container4-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default MoveCategory;
