import React from "react";
import "./MakeTransfer.css";
const MakeTransfer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="make-transfer-main-container">
      <div className="make-transfer-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Make a Transfer</p>
      </div>
      <div className="make-transfer-title-div">
        <p>How to add an transfer transaction in Best Budget</p>
      </div>
      <div className="make-transfer-section-container1">
        <div className="make-transfer-section-container1-description">
          <p>
            1. Click on the Transaction icon located on the navigation bar below
            to create a new transaction.
          </p>
        </div>
        <div className="make-transfer-section-container1-img1-div">
          <div className="make-transfer-section-container1-img1"></div>
        </div>
      </div>
      <div className="make-transfer-section-container2">
        <div className="make-transfer-section-container2-description">
          <p>
            2. By default, the expense transaction type will be selected. Click
            on the 'Transfer' button to switch to creating an transfer
            transaction.
          </p>
        </div>
        <div className="make-transfer-section-container2-img1-div">
          <div className="make-transfer-section-container2-img1"></div>
        </div>
      </div>
      <div className="make-transfer-section-container3">
        <div className="make-transfer-section-container3-description">
          <p>
            3. Enter Transaction Amount: Input the Transfer amount for the
            transaction.
          </p>
          <p>
            4. Transaction Status: Mark whether the transaction should be in a
            clear state or a pending state.{" "}
          </p>
          <p>
            5. Select From Account: Click on the 'From Account' field and choose
            an account you need to transfer amount from.
          </p>
          <p>
            6. Select To Account: Click on the 'To Account' field and choose an
            account you need to transfer amount to.
          </p>
        </div>
        <div className="make-transfer-section-container3-img1-div">
          <div className="make-transfer-section-container3-img1"></div>
        </div>
        <div className="make-transfer-section-container3-img2-div">
          <div className="make-transfer-section-container3-img2"></div>
        </div>
      </div>
      <div className="make-transfer-section-container4">
        <div className="make-transfer-section-container4-description">
          <p>
            7. Note: In Transfer scenarios, category is required only when a
            transfer is between a budget and a non-budget account.
          </p>
          <p>
            8. Select Date: By default, today's date will appear. Click on the
            Date field and choose the desired date for the transaction.
          </p>
          <p>
            9. Enter Memo (Optional): Click on the Memo field and enter any
            optional notes.
          </p>
        </div>
        <div className="make-transfer-section-container4-img1-div">
          <div className="make-transfer-section-container4-img1"></div>
        </div>
        <div className="make-transfer-section-container4-img2-div">
          <div className="make-transfer-section-container4-img2"></div>
        </div>
      </div>
      <div className="make-transfer-section-container5">
        <div className="make-transfer-section-container5-description">
          <p>
            10. Recurring frequency (Optional): Select Recurring frequency if you
            wish to repeat the transaction. 
          </p>
          <p>11. Save Transaction: Click on the
            'Save' button to complete the transaction.</p>
        </div>
        <div className="make-transfer-section-container5-img1-div">
          <div className="make-transfer-section-container5-img1"></div>
        </div>
        <div className="make-transfer-section-container5-img2-div">
          <div className="make-transfer-section-container5-img2"></div>
        </div>
      </div>
     

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default MakeTransfer;
