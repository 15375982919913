import React from "react";
import "./AddPayee.css";
const AddPayee = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="add-payee-main-container">
      <div className="add-payee-breadcrumb">
        <p>Payees</p>
        <p> &gt; </p>
        <p>Add a Payee</p>
      </div>
      <div className="add-payee-title-div">
        <p>How to add a new payee in Best Budget</p>
      </div>
      <div className="add-payee-section-container1">
        <div className="add-payee-section-container1-description">
          <p>
            1. Tap on the 'More' icon in the navigation bar and select the
            'Payees' icon.
          </p>
          <p>
            2. Click on the 'Create Payee' icon located at the top right corner.
          </p>
        </div>
        <div className="add-payee-section-container1-img1-div">
          <div className="add-payee-section-container1-img1"></div>
        </div>
      </div>
      <div className="add-payee-section-container2">
        <div className="add-payee-section-container2-description">
          <p>
            3. Enter the payee name and default payment direction. 
          </p>
          <p>4. Click on
            the Default Category field and choose the desired category.</p>
          <p> 5. Click
            on the 'Save' button to save the new payee.</p>
        </div>
        <div className="add-payee-section-container2-img1-div">
          <div className="add-payee-section-container2-img1"></div>
        </div>
      </div>
      <div className="add-payee-section-container3">
        <div className="add-payee-section-container3-img1-div">
          <div className="add-payee-section-container3-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddPayee;
