import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import LandingWebsite from "./Components/LandingWebsite";
import BestBudgetWay from "./Components/BestBudgetWay";
import PrivacyPolicy from "./Components/Legal/PrivacyPolicy";
import Pricing from "./Components/Pricing";
import ServiceTerms from "./Components/Legal/ServiceTerms";
import Security from "./Components/Legal/Security";
import CookiesPolicy from "./Components/Legal/CookiesPolicy";
import Learn from "./Components/Learn";
import LearningCenter from "./Components/Learn/LearningCenter";
import AboutUs from "./Components/AboutUs";
import { MenuProvider } from './MenuContext';
import EmailVerificationPage from "./Components/EmailVerificationPage";
import Blog from "./Components/Blog";
import FinancialPlanning from "./Components/Blogs/FinancialPlanning";
import BudgetingBlog from "./Components/Blogs/BudgetingBlog";
import InvestingBlog from "./Components/Blogs/InvestingBlog";
import SavingBlog from "./Components/Blogs/SavingBlog";
import SubscriptionPage from "./Components/SubscriptionPage";
const App = () => {
  return (
    <MenuProvider>
      <div className="App">
        <div className="empty-head"></div>
        <BrowserRouter>
          <Header className="head" />
          <div className="browser-router-container">
            <Routes>
              <Route path="/" element={<LandingWebsite />} />
              <Route path="/why-best-budget" element={<BestBudgetWay />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/learn" element={<Learn />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/service-terms" element={<ServiceTerms />} />
              <Route path="/security" element={<Security />} />
              <Route path="/cookies-policy" element={<CookiesPolicy />} />
              <Route path="/learn/learning-center" element={<LearningCenter />} />
              <Route path="/email-verification" element={<EmailVerificationPage />} />
              <Route path="/blog/need-to-achieve-good-outcome-from-financial-planning" element={<FinancialPlanning/>}/>
              <Route path="/blog/how-budgeting-drives-financial-success-and-management" element={<BudgetingBlog/>} />
              <Route path="/blog/top-tips-for-tracking-all-your-investments-in-one-place" element={<InvestingBlog/>}/>
              <Route path="/blog/effective-tips-for-saving-money-quickly-and-easily" element={<SavingBlog/>}/>
              <Route path="/subscription-page" element={<SubscriptionPage />} />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </MenuProvider>

  );
};

export default App;
