import React from "react";
import "./ReopenAccount.css";
const ReopenAccount = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="reopen-account-main-container">
      <div className="reopen-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Reopen Account</p>
      </div>
      <div className="reopen-account-title-div">
        <p>How to Reopen a closed account in Best Budget</p>
      </div>
      <div className="reopen-account-section-container1">
        <div className="reopen-account-section-container1-description">
          <p>
            1. To reopen a closed account, first, tap on the 'Menu' icon located
            in the top left corner of the screen.
          </p>
          <p>2. From the menu options, select 'Closed Accounts'.</p>
        </div>
        <div className="reopen-account-section-container1-img1-div">
          <div className="reopen-account-section-container1-img1"></div>
        </div>
      </div>
      <div className="reopen-account-section-container2">
        <div className="reopen-account-section-container2-description">
          <p>
            3. Choose the closed account you wish to reopen by tapping on its
            field.
          </p>
        </div>
        <div className="reopen-account-section-container2-img1-div">
          <div className="reopen-account-section-container2-img1"></div>
        </div>
      </div>
      <div className="reopen-account-section-container3">
        <div className="reopen-account-section-container3-description">
          <p>
            4. On the detailed page of the closed account, locate and tap the
            'Reopen' button positioned in the top right corner of the screen.
          </p>
          <p>
            5. A window pop-up will appear; select the 'Reopen' option from this
            screen.
          </p>
        </div>
        <div className="reopen-account-section-container3-img1-div">
          <div className="reopen-account-section-container3-img1"></div>
        </div>
      </div>
      <div className="reopen-account-section-container4">
        <div className="reopen-account-section-container3-description">
          <p>
            6. Your desired account will now be reopened with a zero balance as
            the initial amount.
          </p>
          <p>
            If necessary, adjust the account balance by accessing the 'Edit
            Account' option.
          </p>
        </div>
        <div className="reopen-account-section-container4-img1-div">
          <div className="reopen-account-section-container4-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default ReopenAccount;
