import React from "react";
import "./TopCharts.css";
const TopCharts = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="top-charts-main-container">
      <div className="top-charts-breadcrumb">
        <p>Dashboard</p>
        <p> &gt; </p>
        <p>Top Charts</p>
      </div>
      <div className="top-charts-title-div">
        <p>Top Charts in Dashboard</p>
      </div>
      <div className="top-charts-section-container1">
        <div className="top-charts-section-container1-description">
          <p>
            1. Tap on the 'Dashboard' icon located in the bottom navigation bar
            and open Dashboard.
          </p>
          <p>
          2. By default, the Summary view is selected
            upon entering the Dashboard. 
          </p>
          <p>3. Tap on the 'Top Charts' button to
            open the Top charts view.</p>
        </div>
        <div className="top-charts-section-container1-img1-div">
          <div className="top-charts-section-container1-img1"></div>
        </div>
      </div>
      <div className="top-charts-section-container2">
        <div className="top-charts-section-container2-description">
          <p>
          4. Tap on 'Payees' button to open payees.
          </p>
        </div>
        <div className="top-charts-section-container2-img1-div">
          <div className="top-charts-section-container2-img1"></div>
        </div>
      </div>
      <div className="top-charts-section-container3">
        <div className="top-charts-section-container3-description">
          <p>5. Tap on 'Categories' button to open categories.</p>
         
        </div>
        <div className="top-charts-section-container3-img1-div">
          <div className="top-charts-section-container3-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default TopCharts;
