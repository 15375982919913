import React from "react";
import "./DeleteTransactions.css";
const DeleteTransactions = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="delete-transaction-main-container">
      <div className="delete-transaction-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Delete Transaction</p>
      </div>
      <div className="delete-transaction-title-div">
        <p>How to delete a transaction in Best Budget</p>
      </div>
      <div className="delete-transaction-section-container1">
        <div className="delete-transaction-section-container1-description">
          <p>
            1. Go to the 'All Accounts' Transactions page or open the
            transaction details of the desired account.
          </p>
          <p>
            2. Check the checkbox next to the desired transaction to select it
            for deletion.
          </p>
          <p>
            3. From the options below, select the 'Delete' option to proceed
            with the deletion.
          </p>
        </div>
        <div className="delete-transaction-section-container1-img1-div">
          <div className="delete-transaction-section-container1-img1"></div>
        </div>
      </div>
      <div className="delete-transaction-title-div2">
        <p>How to delete multiple transactions in Best Budget</p>
      </div>
      <div className="delete-transaction-section-container2">
        <div className="delete-transaction-section-container2-description">
          <p>
            4. If required, check multiple transactions and select 'Delete' to
            proceed with deleting all the selected transactions.
          </p>
        </div>
        <div className="delete-transaction-section-container2-img1-div">
          <div className="delete-transaction-section-container2-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default DeleteTransactions;
