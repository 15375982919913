import React from "react";
import "./Allocations.css";
const Allocations = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="allocations-main-container">
      <div className="allocations-breadcrumb">
        <p>Dashboard</p>
        <p> &gt; </p>
        <p>Allocations</p>
      </div>
      <div className="allocations-title-div">
        <p>Allocations in Dashboard</p>
      </div>
      <div className="allocations-section-container1">
        <div className="allocations-section-container1-description">
          <p>
            1. Tap on the 'Dashboard' icon located in the bottom navigation bar
            and open Dashboard..
          </p>
          <p>
            2. By default, the Summary view is selected upon entering the
            Dashboard.
          </p>
          <p>
            3. Tap on the 'Allocations' button to open the allocations view.
          </p>
        </div>
        <div className="allocations-section-container1-img1-div">
          <div className="allocations-section-container1-img1"></div>
        </div>
      </div>
      <div className="allocations-section-container2">
        <div className="allocations-section-container2-description">
          <p>
            4. Click on any category group to view the allocations for each
            category within that group.
          </p>
        </div>
        <div className="allocations-section-container2-img1-div">
          <div className="allocations-section-container2-img1"></div>
        </div>
      </div>
      <div className="allocations-section-container3">
        <div className="allocations-section-container3-description">
          <p>5. Click on 'All Groups' to return to the main view.</p>
          <p>
            6. Tap on the 'Next' icon to move to the allocations of the next
            category group.
          </p>
        </div>
        <div className="allocations-section-container3-img1-div">
          <div className="allocations-section-container3-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default Allocations;
