import React from "react";
import "./DeleteAccount.css";
const DeleteAccount = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="delete-account-main-container">
      <div className="delete-account-breadcrumb">
        <p>Profile</p>
        <p> &gt; </p>
        <p>Delete Account</p>
      </div>
      <div className="delete-account-title-div">
        <p>How to delete an account in Best Budget</p>
      </div>
      <div className="delete-account-section-container1">
        <div className="delete-account-section-container1-description">
          <p>
            1. Click on the menu option located on the top right corner of the
            Dashboard. Then, select the 'Settings' option.
          </p>
        </div>
        <div className="delete-account-section-container1-img1-div">
          <div className="delete-account-section-container1-img1"></div>
        </div>
      </div>
      <div className="delete-account-section-container2">
        <div className="delete-account-section-container2-description">
          <p>
          2. Click on the 'Delete Account' field.
          </p>
        </div>
        <div className="delete-account-section-container2-img1-div">
          <div className="delete-account-section-container2-img1"></div>
        </div>
      </div>
      <div className="delete-account-section-container3">
        <div className="delete-account-section-container3-description">
          <p>
          3. Click on the 'Delete Account' button to proceed with deleting the account.
          </p>
        </div>
        <div className="delete-account-section-container3-img1-div">
          <div className="delete-account-section-container3-img1"></div>
        </div>
      </div>
      <div className="delete-account-section-container4">
        <div className="delete-account-section-container4-description">
          <p>
          4. After deleting your account, you'll automatically be redirected to the Sign Up page to start a new account creation process.
          </p>
        </div>
        <div className="delete-account-section-container4-img1-div">
          <div className="delete-account-section-container4-img1"></div>
        </div>
      </div>
      
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default DeleteAccount;
