import React from "react";
import "./EditPayee.css";

const EditPayee = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="edit-payee-main-container">
      <div className="edit-payee-breadcrumb">
        <p>Payees</p>
        <p> &gt; </p>
        <p>Edit Payee</p>
      </div>
      <div className="edit-payee-title-div">
        <p>How to edit payee in Best Budget</p>
      </div>
      <div className="edit-payee-section-container1">
        <div className="edit-payee-section-container1-description">
          <p>1. Click on the desired payee field to edit it.</p>
        </div>
        <div className="edit-payee-section-container1-img1-div">
          <div className="edit-payee-section-container1-img1"></div>
        </div>
      </div>
      <div className="edit-payee-section-container2">
        <div className="edit-payee-section-container2-description">
          <p>
            2. Modify the required details such as name, payment direction, and
            associated categories.
          </p>
          <p>
            3. Click on the 'Save' button to save the updated payee information.
          </p>
        </div>
        <div className="edit-payee-section-container2-img1-div">
          <div className="edit-payee-section-container2-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default EditPayee;
