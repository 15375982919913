import React from "react";
import "./CloseAccount.css";
const CloseAccount = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="close-account-main-container">
      <div className="close-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Close Account</p>
      </div>
      <div className="close-account-title-div">
        <p>How to close an account in Best Budget</p>
      </div>
      <div className="close-account-section-container1">
        <div className="close-account-section-container1-description">
          <p>
            1. Click on the account field for the account you wish to edit to
            access its details.
          </p>
        </div>
        <div className="close-account-section-container1-img1-div">
          <div className="close-account-section-container1-img1"></div>
        </div>
      </div>
      <div className="close-account-section-container2">
        <div className="close-account-section-container2-description">
          <p>
            2. On the account details page, tap on the "Menu" icon located in
            the top right corner.
          </p>
          <p>3. From the menu options, select "Edit Account".</p>
        </div>
        <div className="close-account-section-container2-img1-div">
          <div className="close-account-section-container2-img1"></div>
        </div>
      </div>
      <div className="close-account-section-container3">
        <div className="close-account-section-container3-description">
          <p>
            4. On the 'Edit Account' page, locate and click on the "Close"
            button positioned in the top right corner.
          </p>
          <p>
            5. A window pop-up will appear; select the 'Close' option from this
            screen to confirm the closure of the account.
          </p>
        </div>
        <div className="close-account-section-container3-img1-div">
          <div className="close-account-section-container3-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default CloseAccount;
