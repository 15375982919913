import React from "react";
import "./EditProfileDetails.css";
const EditProfileDetails = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="edit-profile-details-main-container">
      <div className="edit-profile-details-breadcrumb">
        <p>Profile</p>
        <p> &gt; </p>
        <p>Edit Profile details</p>
      </div>
      <div className="edit-profile-details-title-div">
        <p>How to Edit Profile details in Best Budget</p>
      </div>
      <div className="edit-profile-details-section-container1">
        <div className="edit-profile-details-section-container1-description">
          <p>
            1. Click on the menu option located on the top right corner of the
            Dashboard. Then, select the 'Settings' option.
          </p>
          
        </div>
        <div className="edit-profile-details-section-container1-img1-div">
          <div className="edit-profile-details-section-container1-img1"></div>
        </div>
      </div>
      <div className="edit-profile-details-section-container2">
        <div className="edit-profile-details-section-container2-description">
          <p>2. In the settings, locate the display name field and click on it to edit.</p>
          
        </div>
        <div className="edit-profile-details-section-container2-img1-div">
          <div className="edit-profile-details-section-container2-img1"></div>
        </div>
      </div>
      <div className="edit-profile-details-section-container3">
        <div className="edit-profile-details-section-container3-description">
          <p>3. Enter the new name in the display name field. </p>
          <p>4. After entering the new name, click on the 'Save' button to save the changes.</p>
        </div>
        <div className="edit-profile-details-section-container3-img1-div">
          <div className="edit-profile-details-section-container3-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default EditProfileDetails;
