import React from "react";
import "./PrivacyPolicy.css";
import Footer from "../Footer";
import SEO from "../SEO";
const PrivacyPolicy = (props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <React.Fragment>
    <SEO
        title="Privacy Policy"
        description="Track your expenses effortlessly with our user-friendly expense tracker application. Stay on top of your finances, create budgets, and get detailed reports to make informed financial decisions. Try it now for free!."
      />
      <div className="privacy-policy-main-container">
        <div className="pp-actual-content-container">
          <div className="pp-first-section">
            <div className="pp-heading1">
              <p>Privacy Policy</p>
            </div>
            <div className="pp-last-updated-div">
              <p>Last updated: 1st April 2024</p>
            </div>
            <div className="pp-description1">
              <p>
                We value your privacy, especially when it comes to personal
                finance like ‘Best Budget’. CREXIN Technologies Private Limited.
                (“CREXIN”), the creator of Best Budget is committed to the
                privacy of all our users.
              </p>
              <p>
                This privacy policy outlines the information you might share or
                that we may collect while using our personal finance software,
                accessible through our websites, services, and applications
                (referred to as the “Service”). Here, we detail our procedures
                for gathering, utilising, maintaining, and safeguarding this
                personal information.
              </p>
              <p>
                This policy covers all information collected by the Service or
                provided to it via our mobile and desktop applications for
                accessing the Service, as well as any information exchanged
                through email or other electronic communication channels between
                you and the Service.
              </p>
              <p>
                “Users" refer to individuals who have registered an account with
                us and have logged into that account using any aspect of the
                Service, such as the website bestbudget.in or any mobile or
                desktop applications downloaded from bestbudget.in.
              </p>
              <p>
                Please review this policy carefully to understand how we handle
                personal information provided to and collected by the Service.
                Your decision to use the Service signifies your agreement with
                our privacy policy.
              </p>
              <p>
                Best Budget may update this privacy policy periodically, so
                please check it regularly for any changes. The date at the top
                of the privacy policy indicates the last time it was updated.
              </p>
            </div>
          </div>
          <div className="pp-sub-section1">
            <h3 className="pp-sub-section1-title">Your ‘Personal Data’</h3>
            <p>
              Information, including personal data, is collected through various
              methods when you utilize the Service. In this context, "personal
              information" refers to any data that identifies you as an
              individual, such as your name and email address.
            </p>
            <p>
              You have the option to utilize the Service without providing any
              personally identifiable information. However, to register an
              account, you will need to provide your name, email address, and
              password. These details can all be created anonymously for the
              purpose of registering for the Service.
            </p>
            <p>
              After registration, the Service may gather different kinds of
              information about Users, including the following.
              <ol type="1">
                <li>
                  Information about financial institutions and accounts provided
                  during Service setup.
                </li>
                <li>
                  Account login details, such as usernames, passwords, and
                  multi-factor authentication information, for accounts
                  connected to the Service through bank feed aggregation. This
                  data is transmitted to our bank feed provider for additional
                  processing and is not retained on Best Budget servers.
                </li>
                <li>
                  Financial account details retrieved from connected accounts
                  within the Service or manually imported into the Service,
                  encompassing account balances and transaction records.
                </li>
                <li>
                  Details regarding the usage of devices used to access the
                  Service, such as internet connection data, collected through
                  the methods described below.
                </li>
              </ol>
            </p>
            <p>
              The information described above is collected via the methods
              outlined below.
              <ol type="1">
                <li>Through third-party bank feed aggregation </li>
                <li>
                  When a user directly provides the information to the Service.
                </li>
                <li>
                  Automatically as users navigate through the website
                  bestbudget.in. This information may include usage details, IP
                  addresses, and other data collected through cookies or other
                  tracking technologies, as outlined in{" "}
                  <span>
                    <a target="blank" href="https://www.bestbudget.in/cookies-policy">Cookie Policy</a>
                  </span>
                  .{" "}
                </li>
              </ol>
            </p>
          </div>
          <div className="pp-sub-section2">
            <h3 className="pp-sub-section2-title">
            Information Submitted By The User
            </h3>
            <p>
              While using the Service, you might choose to provide information
              voluntarily through various means, as outlined below:
              <ol type="1">
                <li>
                  When completing forms on our website or applications to
                  register for the Service using your email address, or when
                  entering information into forms while using the Service to
                  complete tasks.
                </li>
                <li>
                  When you reach out to us for assistance, to report a problem
                  with the Service, or for other general inquiries
                </li>
                <li>
                  When entering billing information while purchasing a
                  subscription for the Service.
                </li>
                <li>
                  When voluntarily submitting responses to surveys requested by
                  Best Budget.
                </li>
              </ol>
            </p>
          </div>
          <div className="pp-sub-section3">
            <h3 className="pp-sub-section3-title">
            Automatically Collected Information
            </h3>
            <p>
              As you explore our website bestbudget.in and utilize the Service,
              we may employ automated technologies to gather data on how you use
              the Service. This information may include:
              <ol type="1">
                <li>
                  Information about the device used to access the Service,
                  including details about the operating system.
                </li>
                <li>
                  Usage patterns and internet connection details, such as
                  website browsing patterns, traffic logs, and IP address
                  information.
                </li>
                <li>
                  Information about mobile devices and other relevant data when
                  accessing our official mobile applications. This aids in
                  enhancing our applications, identifying, and resolving bugs,
                  and improving overall performance.
                </li>
                <li>
                  Information about crashes and errors occurring within the
                  application.
                </li>
              </ol>
            </p>
            <p>
              The technologies utilized for this automatic data collection
              encompass cookies and other technologies like “clear gifs.”
              Further details on these can be found in our Cookie Policy.
            </p>
          </div>
          <div className="pp-sub-section4">
            <h3 className="pp-sub-section4-title">
            The Purposes For Which Your Information Is Utilized
            </h3>
            <p>
              The information collected or provided to the Service is solely
              used to facilitate Best Budget in providing the Service. Further
              details are provided below.
              <ol type="1">
                <li>Providing you with access to the Service. </li>
                <li>Allowing you to register an account with the Service.</li>
                <li>
                  Verifying your identity and authorizing your devices to use
                  the Service.
                </li>
                <li>
                  Improving the Service, applications, and overall business
                  operations based on your usage experience.
                </li>
                <li>
                  Communicating important updates, technical notices, or changes
                  related to your Service account.
                </li>
                <li>
                  Enabling the connection of third-party financial institution
                  accounts to the Service and retrieving account balances and
                  transactions.
                </li>
                <li>
                  Fulfilling any contractual obligations, you enter the Service,
                  including billing.
                </li>
                <li>
                  Offering email-based customer support, diagnosing issues, and
                  responding to inquiries about the Service.
                </li>
                <li>
                  Completing any purposes specified when providing information
                  to the Service.
                </li>
                <li>
                  Safeguarding against and investigating fraudulent,
                  unauthorized, or illegal activities.
                </li>
              </ol>
            </p>
          </div>
          <div className="pp-sub-section5">
            <h3 className="pp-sub-section5-title">
            Your Information Disclosure
            </h3>
            <p>
              Best Budget ensures that under no circumstances will your personal
              information be sold. Best Budget will exclusively utilize such
              data in an anonymized manner, solely for generating statistics
              about the usage of Best Budget within our community.
            </p>
            <p>
              We retain the authority to anonymize or consolidate financial data
              by removing all personal identifiers, rendering the data
              unidentifiable to individuals. This anonymized data serves the
              purpose of analysing usage patterns, enabling us to enhance and
              refine the Service, or to furnish aggregate statistics regarding
              Best Budget usage within our community.
            </p>
            <p>
              The privacy policy outlines scenarios in which personal
              information, as defined herein, could be revealed:
              <ol type="1">
                <li>
                  Disclosure to service providers, contractors, or other third
                  parties is limited to those bound by legal obligations to
                  maintain the confidentiality of your personal information.
                  They are authorized to use the information solely for the
                  purposes specified by the Service.
                </li>
                <li>
                  Your financial accounts and information may be aggregated
                  through our third-party bank feed provider.
                </li>
                <li>
                  In the event of a sale, acquisition, merger, reorganization,
                  or other transfer involving the Service, including the
                  transfer of assets containing personal information, such
                  information may be disclosed to a third-party purchaser or
                  successor. We will make every effort to ensure that the new
                  entity adheres to this privacy policy.
                </li>
                <li>
                  Information provided to the Service will be disclosed for the
                  sole purpose for which it was provided or as described at the
                  time of submission in order to fulfil the intended purpose.
                </li>
              </ol>
            </p>
            <p>
              We reserve the right to disclose your personal information under
              the following circumstances:
              <ol type="1">
                <li>
                  Compliance with any court order, law, or legal process,
                  including responding to government or regulatory requests.
                </li>
                <li>
                  Enforcement or application of our terms of service and other
                  agreements, including billing purposes.
                </li>
                <li>
                  If we deem disclosure necessary or appropriate to safeguard
                  the rights, property, or safety of Best Budget, our customers,
                  or others. This may involve exchanging information with other
                  companies and organizations to prevent fraud and reduce credit
                  risk.
                </li>
              </ol>
            </p>
          </div>
          <div className="pp-sub-section6">
            <h3 className="pp-sub-section6-title">Retention Of Information</h3>
            <p>
              Your data is kept for the duration of your active account with the
              Service. Upon opting to delete your account, the following
              procedures are executed:
              <ol type="1">
                <li>
                  Permanent deletion of all financial account and transaction
                  details from production databases after a hold period of 90
                  days.
                </li>
                <li>
                  Erasure of all information from our third-party bank feed
                  provider’s database, including any stored username and
                  password information.
                </li>
                <li>
                  Utilization of available tools in collaboration with
                  third-party service providers to eliminate or anonymize any
                  stored personal information.
                </li>
              </ol>
            </p>
            <p>
              Encrypted backups are securely stored at an offsite location,
              subject to stringent access control policies for retrieval and
              decryption. These backups are maintained for a duration of one
              year, after which they are permanently eliminated.
            </p>
            <p className="faq">
              Correcting and accessing your personal information
            </p>
            <p>
              You can review and rectify the personal information you have
              provided to the Service by logging into the platform through a web
              browser and navigating to the User Preferences section.
            </p>
            <p className="faq">Frequency of Privacy Policy Updates?</p>
            <p>
              We may periodically update this Privacy Policy to account for
              changes in our operational, legal, or regulatory practices. We
              encourage you to revisit this Privacy Policy regularly to stay
              informed. The date at the top of this policy signifies the last
              update.
            </p>
            <p className="faq"> Where can I get further information? </p>
            <p>
              If you have any questions about our Privacy Policy, please email
              us at {" "}
              <a href="mailto:support@bestbudget.in">support@bestbudget.in</a>
            </p>
          </div>
          <div className="pp-go-to-top">
            <div
              className="go-to-page-top"
              onClick={() => {
                scrollToTop();
              }}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
