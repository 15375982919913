import React from "react";
import { useNavigate } from "react-router-dom";
import "./Blog.css";
import Footer from "./Footer";
import financialPlanning from "../Assets/Blog/financialPlanning.webp";
import budgetingImage from "../Assets/Blog/budgeting.webp";
import InvestingImage from "../Assets/Blog/investing.webp";
import savingsImage from "../Assets/Blog/savings.webp";
const Blog = () => {
    const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", 
        });
      };
    return (
        <React.Fragment>
            <div className="blog-main-container">
                <div className="blog-section1-container">
                    <div className="blog-section1-content">
                        <div className="blog-section1-content-description">
                            <h1 className="blog-h2">BLOG</h1>
                            <h3
                                className="blog-title"
                                onClick={() => {
                                    navigate(
                                        "/blog/need-to-achieve-good-outcome-from-financial-planning"
                                    );
                                }}
                            >
                                Need to achieve good outcome from Financial Planning ?
                            </h3>
                            <div className="blog-section1-content-desc-text">
                                <p>
                                    Financial planning is an essential aspect of achieving
                                    long-term financial success and stability. Whether you're just
                                    starting your career, planning for a family, or looking toward
                                    retirement, effective financial planning can help you reach
                                    your goals. In this blog, we'll explore the key elements of
                                    financial planning and provide actionable steps to ensure you
                                    achieve a good outcome.
                                </p>
                                <p
                                    className="read-more-text"
                                    onClick={() => {
                                        navigate(
                                            "/blog/need-to-achieve-good-outcome-from-financial-planning"
                                        );
                                    }}
                                >
                                    Read more...
                                </p>
                            </div>
                        </div>
                        <div className="blog-section1-content-image-div">
                            <div className="blog-section1-content-image" >
                                <img src={financialPlanning} alt="financial planning" onClick={() => {
                                    navigate(
                                        "/blog/need-to-achieve-good-outcome-from-financial-planning"
                                    );
                                }} />
                            </div>
                        </div>
                    </div>
                    <h1 className="related-topics-heading">Related Topics</h1>
                    <div className="blog-section1-grid-container">
                        <div
                            className="blog-grid1 grid"
                            onClick={() => {
                                navigate(
                                    "/blog/how-budgeting-drives-financial-success-and-management"
                                );
                                scrollToTop();
                            }}
                        >
                            <img src={budgetingImage} alt="" />
                            <h5>How Budgeting Drives Financial Success and Management</h5>
                            <p>
                                Budgeting is the cornerstone of effective financial management
                                and a crucial driver of financial success. It involves creating
                                a plan to manage your money, tracking your income and expenses,
                                and making informed financial decisions.
                            </p>
                        </div>
                        <div
                            className="blog-grid2 grid"
                            onClick={() => {
                                navigate(
                                    "/blog/top-tips-for-tracking-all-your-investments-in-one-place"
                                );
                                scrollToTop();
                            }}
                        >
                            <img src={InvestingImage} alt="" />
                            <h5>Top Tips for Tracking All Your Investments in One Place</h5>
                            <p>
                                Investing can be an exciting journey towards financial freedom.
                                But with a diverse portfolio spread across different accounts,
                                tracking it all can feel overwhelming.
                            </p>
                        </div>
                        <div
                            className="blog-grid3 grid"
                            onClick={() => {
                                navigate(
                                    "/blog/effective-tips-for-saving-money-quickly-and-easily"
                                );
                                scrollToTop();
                            }}
                        >
                            <img src={savingsImage} alt="" />
                            <h5>Effective Tips for Saving Money Quickly and Easily</h5>
                            <p>
                                Living paycheck to paycheck? Feeling the pinch of inflation?
                                Don't worry, you're not alone. But fret not, fellow budget
                                warriors! Here's a treasure trove of practical tips to help you
                                save money quickly and easily, putting you back in control of
                                your finances.
                            </p>
                        </div>
                        <div className="blog-grid1 grid">
                            <img src={budgetingImage} alt="" />
                        </div>
                        <div className="blog-grid2 grid">
                            <img src={budgetingImage} alt="" />
                        </div>
                        <div className="blog-grid3 grid">
                            <img src={budgetingImage} alt="" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default Blog;
