import React from "react";
import "./AddCategory.css";
const AddCategory = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="add-category-main-container">
      <div className="add-category-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Add Category</p>
      </div>
      <div className="add-category-title-div">
        <p>How to add a Category in Best Budget</p>
      </div>
      <div className="add-category-section-container1">
        <div className="add-category-section-container1-description">
          <p>
            1. On your Best Budget App, open Budget page and tap on “Manage
            Categories” icon located on the top right corner.
          </p>
        </div>
        <div className="add-category-section-container1-img1-div">
          <div className="add-category-section-container1-img1"></div>
        </div>
      </div>
      <div className="add-category-section-container2">
        <div className="add-category-section-container2-description">
          <p>
            2. Locate the category group where you want to add categories.
          </p>
          <p> 3.
            Look for the 'add' icon positioned near the category group name,
            typically on the right side and tap on it for adding categories and
            click on 'Done' button to save.</p>
        </div>
        <div className="add-category-section-container2-img1-div">
          <div className="add-category-section-container2-img1"></div>
        </div>
      </div>
     

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddCategory;
