import React from "react";
import "./AddAccount.css";
const AddAccount = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="add-account-main-container">
      <div className="add-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Add Account</p>
      </div>
      <div className="add-account-title-div">
        <p>How to add an account (Unlinked) in Best Budget</p>
      </div>
      <div className="add-account-section-container1">
        <div className="add-account-section-container1-description">
          <p>
            1. On your Best Budget App, open Accounts page and tap on “ADD”
            Button.
          </p>
        </div>
        <div className="add-account-section-container1-img1-div">
          <div className="add-account-section-container1-img1"></div>
        </div>
      </div>
      <div className="add-account-section-container2">
        <div className="add-account-section-container2-description">
          <p>
            2. Provide a name for your account that clearly identifies your
            actual bank account.
          </p>
        </div>
        <div className="add-account-section-container2-img1-div">
          <div className="add-account-section-container2-img1"></div>
        </div>
      </div>
      <div className="add-account-section-container3">
        <div className="add-account-section-container3-description">
          <p>
            3. From the list of given account types, select the type that
            matches your bank account.
          </p>
        </div>
        <div className="add-account-section-container3-img1-div">
          <div className="add-account-section-container3-img1"></div>
        </div>
      </div>
      <div className="add-account-section-container4">
        <div className="add-account-section-container4-description">
          <p>
            4. Enter the initial balance amount available in your bank account.
            You can toggle between positive or negative balance using the
            provided option.
          </p>
          <p>5. Optionally, add any preferred notes to your account.</p>
          <p>
            6. Once all necessary details are entered, click on the "Save"
            button to create the new account.
          </p>
        </div>
        <div className="add-account-section-container4-img1-div">
          <div className="add-account-section-container4-img1"></div>
        </div>
        <div className="add-account-section-container4-img2-div">
          <div className="add-account-section-container4-img2"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddAccount;
