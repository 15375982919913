import React from "react";
import "./SearchTransactions.css";
const SearchTransactions = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="search-transaction-main-container">
      <div className="search-transaction-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Search Transactions</p>
      </div>
      <div className="search-transaction-title-div">
        <p>How to Search Transactions in Best Budget</p>
      </div>
      <div className="search-transaction-section-container1">
        <div className="search-transaction-section-container1-description">
          <p>
          1. Go to the 'All Accounts' Transactions page or open the transaction details of the desired account. 
          </p>
          <p>2. Click on the search field to search the desired transactions.</p>
        </div>
        <div className="search-transaction-section-container1-img1-div">
          <div className="search-transaction-section-container1-img1"></div>
        </div>
      </div>
      <div className="search-transaction-section-container2">
        <div className="search-transaction-section-container2-description">
          <p>
          3. Type the transaction name or any relevant keyword, then press Enter to execute the search.
          </p>
        </div>
        <div className="search-transaction-section-container2-img1-div">
          <div className="search-transaction-section-container2-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default SearchTransactions;
