import React from "react";
import "./HideCateGroup.css";
const HideCateGroup = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="hide-cate-group-main-container">
      <div className="hide-cate-group-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Hide Category Group</p>
      </div>
      <div className="hide-cate-group-title-div">
        <p>How to Hide a Category group in Best Budget</p>
      </div>
      <div className="hide-cate-group-section-container1">
        <div className="hide-cate-group-section-container1-description">
          <p>
            1. On your Best Budget App, open Budget page and tap on “Manage
            Categories” icon located on the top right corner.
          </p>
        </div>
        <div className="hide-cate-group-section-container1-img1-div">
          <div className="hide-cate-group-section-container1-img1"></div>
        </div>
      </div>
      <div className="hide-cate-group-section-container2">
        <div className="hide-cate-group-section-container2-description">
          <p>2. Locate the desired category group you wish to Hide.</p>
          <p>
            3. Click on the ''menu'' icon positioned near the left side of the
            category group.
          </p>
          <p> 4. Select "Hide" option.</p>
        </div>
        <div className="hide-cate-group-section-container2-img1-div">
          <div className="hide-cate-group-section-container2-img1"></div>
        </div>
      </div>
      <div className="hide-cate-group-title-div2">
        <p>How to Show a hidden Category group in Best Budget</p>
      </div>
      <div className="hide-cate-group-section-container3">
        <div className="hide-cate-group-section-container3-description">
          <p>
            1. On your Best Budget App, open Budget page and tap on “Manage
            Categories” icon located on the top right corner.
          </p>
        </div>
        <div className="hide-cate-group-section-container3-img1-div">
          <div className="hide-cate-group-section-container3-img1"></div>
        </div>
      </div>
      <div className="hide-cate-group-section-container4">
        <div className="hide-cate-group-section-container4-description">
          <p>2. Click on the 'Hidden Categories' button located at the top.</p>
        </div>
        <div className="hide-cate-group-section-container4-img1-div">
          <div className="hide-cate-group-section-container4-img1"></div>
        </div>
      </div>
      <div className="hide-cate-group-section-container5">
        <div className="hide-cate-group-section-container5-description">
          <p>3. Find the desired category group you wish to show.</p>
          <p>
            4. Click on the 'show' icon associated with the desired category
            group to show it along with its categories.
          </p>
          <p>
            5. Alternatively, you can click on the 'Show All' button to show
            all hidden category groups and categories at once.
          </p>
        </div>
        <div className="hide-cate-group-section-container5-img1-div">
          <div className="hide-cate-group-section-container5-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default HideCateGroup;
