import React from "react";
import "./CollectiveAllocation.css";

const CollectiveAllocation = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="collective-allocation-main-container">
      <div className="collective-allocation-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Collective Allocation</p>
      </div>
      <div className="collective-allocation-title-div">
        <p>How to allocate all categories collectively in Best Budget</p>
      </div>
      <div className="collective-allocation-section-container1">
        <div className="collective-allocation-section-container1-description">
          <p>
            1. On your Best Budget App, open Accounts page and tap on “Allocate”
            button.
          </p>
        </div>
        <div className="collective-allocation-section-container1-img1-div">
          <div className="collective-allocation-section-container1-img1"></div>
        </div>
      </div>
      <div className="collective-allocation-section-container2">
        <div className="collective-allocation-section-container2-description">
          <p>
            2. Choose from the provided options such as 'Average Spent',
            'Allocated Last Month', or 'Spent Last Month' to allocate the amount
            to all category groups and categories collectively, based on
            previous statistics.
          </p>
        </div>
        <div className="collective-allocation-section-container2-img1-div">
          <div className="collective-allocation-section-container2-img1"></div>
        </div>
      </div>
      <div className="collective-allocation-section-container3">
        <div className="collective-allocation-section-container3-description">
          <p>
          3. Click on the 'Allocate' option to confirm and execute the allocation.
          </p>
        </div>
        <div className="collective-allocation-section-container3-img1-div">
          <div className="collective-allocation-section-container3-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default CollectiveAllocation;
