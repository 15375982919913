import React from "react";
import "./AddExpense.css";
const AddExpense = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="add-expense-main-container">
      <div className="add-expense-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Add Expense</p>
      </div>
      <div className="add-expense-title-div">
        <p>How to add an expense transaction in Best Budget</p>
      </div>
      <div className="add-expense-section-container1">
        <div className="add-expense-section-container1-description">
          <p>
            1. Click on the Transaction icon located on the navigation bar below
            to create a new transaction.
          </p>
        </div>
        <div className="add-expense-section-container1-img1-div">
          <div className="add-expense-section-container1-img1"></div>
        </div>
      </div>
      <div className="add-expense-section-container2">
        <div className="add-expense-section-container2-description">
          <p>
            2. By default, the expense transaction type will be selected. Click
            on the 'Income' button to switch to creating an income transaction.
          </p>
          <p>
            3. Enter Transaction Amount: Input the expense amount for the
            transaction.
          </p>
          <p>
            4. Transaction Status: Mark whether the transaction should be in a
            clear state or a pending state.
          </p>
        </div>
        <div className="add-expense-section-container2-img1-div">
          <div className="add-expense-section-container2-img1"></div>
        </div>
      </div>
      <div className="add-expense-section-container3">
        <div className="add-expense-section-container3-description">
          <p>
            5. Select Payee: Click on the Payee field and choose an existing
            payee or create a new one for the income transaction.
          </p>
        </div>
        <div className="add-expense-section-container3-img1-div">
          <div className="add-expense-section-container3-img1"></div>
        </div>
        
      </div>
      <div className="add-expense-section-container4">
        <div className="add-expense-section-container4-description">
          <p>
            6. Select Category: Click on the Category field and choose an
            existing category or create a new one for the income transaction. 
          </p>
          
        </div>
        <div className="add-expense-section-container4-img1-div">
          <div className="add-expense-section-container4-img1"></div>
        </div>
      </div>
      <div className="add-expense-section-container5">
        <div className="add-expense-section-container5-description">
        <p>7.
            Select Account: Click on the Account field and select the desired
            account for the transaction.</p>
        </div>
        <div className="add-expense-section-container5-img1-div">
          <div className="add-expense-section-container5-img1"></div>
        </div>
      </div>
      <div className="add-expense-section-container6">
        <div className="add-expense-section-container6-description">
          <p>
            8. Select Date: Click on the Date field and choose the date for the
            transaction.
          </p>
          <p>
            9. Enter Memo (Optional): Click on the Memo field and enter any
            optional notes.
          </p>
        </div>
        <div className="add-expense-section-container6-img1-div">
          <div className="add-expense-section-container6-img1"></div>
        </div>
      </div>
      <div className="add-expense-section-container7">
        <div className="add-expense-section-container7-description">
          <p>
            10.Recurring frequency (Optional): Select Recurring frequency for
            the Transaction.
          </p>
          <p>
            11. Save Transaction: Click on the 'Save' button to complete the
            transaction.
          </p>
        </div>
        <div className="add-expense-section-container7-img1-div">
          <div className="add-expense-section-container7-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddExpense;
