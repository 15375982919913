import React from "react";
import "./DeleteCategory.css";
const DeleteCategory = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="delete-category-main-container">
      <div className="delete-category-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Delete Category</p>
      </div>
      <div className="delete-category-title-div">
        <p>How to Delete a category in Best Budget</p>
      </div>
      <div className="delete-category-section-container1">
        <div className="delete-category-section-container1-description">
          <p>
            1. On your Best Budget App, open Budget page and tap on “Manage
            Categories” icon located on the top right corner.
          </p>
        </div>
        <div className="delete-category-section-container1-img1-div">
          <div className="delete-category-section-container1-img1"></div>
        </div>
      </div>
      <div className="delete-category-section-container2">
        <div className="delete-category-section-container2-description">
          <p>2. Locate the desired category you wish to delete.</p>
          <p>
            {" "}
            3. Click on the "menu" icon positioned near the left side of the
            category.
          </p>
          <p>4.Select "Delete" option.</p>
        </div>
        <div className="delete-category-section-container2-img1-div">
          <div className="delete-category-section-container2-img1"></div>
        </div>
      </div>
      <div className="delete-category-section-container3">
        <div className="delete-category-section-container3-description">
          <p>
            Note: If there are no allocations for a particular category, you can
            delete it directly. 
          </p>
          <p>If there are allocations, you can transfer the
            amount to another category and then delete it.</p>
        </div>
        <div className="delete-category-section-container3-img1-div">
          <div className="delete-category-section-container3-img1"></div>
        </div>
      </div>
      <div className="delete-category-section-container4">
        <div className="delete-category-section-container4-description">
          <p>
            5. Select the category you want to transfer the amount to and click
            on 'Submit' button.
          </p>
        </div>
        <div className="delete-category-section-container4-img1-div">
          <div className="delete-category-section-container4-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default DeleteCategory;
