import React from "react";
import "./DeleteCateGroup.css";

const DeleteCateGroup = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="delete-cate-group-main-container">
      <div className="delete-cate-group-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Delete Category Group</p>
      </div>
      <div className="delete-cate-group-title-div">
        <p>How to Delete a category group in Best Budget</p>
      </div>
      <div className="delete-cate-group-section-container1">
        <div className="delete-cate-group-section-container1-description">
          <p>
            1. On your Best Budget App, open Budget page and tap on “Manage
            Categories” icon located on the top right corner.
          </p>
        </div>
        <div className="delete-cate-group-section-container1-img1-div">
          <div className="delete-cate-group-section-container1-img1"></div>
        </div>
      </div>
      <div className="delete-cate-group-section-container2">
        <div className="delete-cate-group-section-container2-description">
          <p>2. Locate the desired category group you wish to delete.</p>
          <p>
            {" "}
            3. Click on the ''menu'' icon positioned near the left side of the
            category group.
          </p>
          <p>4.Select "Delete" option.</p>
        </div>
        <div className="delete-cate-group-section-container2-img1-div">
          <div className="delete-cate-group-section-container2-img1"></div>
        </div>
      </div>
      <div className="delete-cate-group-section-container3">
        <div className="delete-cate-group-section-container3-description">
          <p>
            Note: When there are active categories with allocated amounts in a
            category group, it cannot be deleted directly. You must transfer the
            allocations to another category group before deletion.
          </p>
        </div>
        <div className="delete-cate-group-section-container3-img1-div">
          <div className="delete-cate-group-section-container3-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default DeleteCateGroup;
