import React from "react";
import "./Upcoming.css";
const Upcoming = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  return (
    <div className="upcoming-main-container">
      <div className="upcoming-breadcrumb">
        <p>Dashboard</p>
        <p> &gt; </p>
        <p>Upcoming</p>
      </div>
      <div className="upcoming-title-div">
        <p>Upcoming Transactions in Dashboard</p>
      </div>
      <div className="upcoming-section-container1">
        <div className="upcoming-section-container1-description">
          <p>
            1. Tap on the 'Dashboard' icon located in the bottom navigation bar
            and open Dashboard.
          </p>
          <p>
            2. By default, the Summary view is selected upon entering the
            Dashboard.
          </p>
          <p> 3. Tap on the 'Upcoming' button to open
            the scheduled Transactions.</p>
        </div>
        <div className="upcoming-section-container1-img1-div">
          <div className="upcoming-section-container1-img1"></div>
        </div>
      </div>
      <div className="upcoming-section-container2">
        <div className="upcoming-section-container2-description">
          <p>4. Click on desired scheduled transaction to edit it.</p>
        </div>
        <div className="upcoming-section-container2-img1-div">
          <div className="upcoming-section-container2-img1"></div>
        </div>
      </div>
      <div className="upcoming-section-container3">
        
        <div className="upcoming-section-container3-img1-div">
          <div className="upcoming-section-container3-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default Upcoming;
